<template>
  <div>
    <b-card
      title="จัดการเครดิต"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="tableQuery.phoneno"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-card-body v-if="memberInfo" />
        <b-row v-if="memberInfo">
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              เบอร์โทร / ยูส
            </h5>
            <b-card-text>
              {{ memberInfo.phoneno }} / {{ memberInfo.username }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              ชื่อ-สกุล
            </h5>
            <b-card-text>
              {{ memberInfo.fname }} {{ memberInfo.lname }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              บัญชี
            </h5>
            <b-card-text>
              <img
                :src="getBankImage(memberInfo.bank_code)"
                style="width: 24px; margin-bottom:5px"
              > {{ memberInfo.bank_name }} - {{ memberInfo.bank_acc_no }}
            </b-card-text>
          </b-col>
          <b-col
            md="4"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              เครดิตปัจจุบัน
            </h5>
            <b-card-text class="text-right">
              {{ memberInfo.wallet.credit }}
            </b-card-text>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card-body>

      <b-table
        v-if="false"
        ref="membertable"
        striped
        hover
        responsive
        class="position-relative"
        :busy.sync="isBusy"
        :per-page="tableQuery.page_size"
        :current-page="tableQuery.page"
        :items="fetchRecords"
        :fields="fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
        <template #cell(fullname)="data">
          {{ `${data.item.fname} ${data.item.lname}` }}
        </template>
        <template #cell(note)="data">
          {{ `[${data.item.platform}][${data.item.txtype}` }}] {{ `${data.item.note}` }}
        </template>
        <template #cell(amount)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.amount)}` }}
          </div>
        </template>
        <template #cell(balance)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.balance)}` }}
          </div>
        </template>
        <template #cell(get_promo)="data">
          <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
            {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
          </b-badge>
        </template>
        <template #cell(invited_by)="data">
          <b-badge
            v-if="!data.item.invited_by"
            :variant="'light-danger'"
          >
            ไม่มี
          </b-badge>
          <div v-if="data.item.invited_by">
            {{ data.item.refer_by.phoneno }}<br>
            {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
          </div>
        </template>
        <template #cell(status)="data">
          <div style="text-align:center;">
            <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
              {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
            </b-badge><br>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :variant="{'Y':'outline-danger', 'N':'outline-success'}[data.item.is_active]"
              size="sm"
              @click="updateMemberStatus(data.item, data.item.is_active === 'Y' ? 'inactive' : 'active')"
            >
              {{ {'Y':'ระงับยูสนี้', 'N':'แอคทีฟยูสนี้'}[data.item.is_active] }}
            </b-button>
          </div>
        </template>
        <template #cell(afflink)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="copyReferralLink(data.item)"
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              ก๊อปลิ้งชวนเพื่อน
            </b-button>
          </div>
        </template>
        <template #cell(btn_credit)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="updateCredit(data.item)"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                class="mr-50"
              />
              เครดิต
            </b-button>
          </div>
        </template>
        <template #cell(btn_detail)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="viewMember(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              แก้ไขข้อมูล
            </b-button>
          </div>
        </template>

      </b-table>
    </b-card>
    <b-row v-if="memberInfo">
      <b-col md="12">
        <b-card>
          <h2>อัพไลน์</h2>
          <b-table
            ref="uplineTable"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="[affiliateInfo.upline]"
            :fields="affFieldsUL"
          >
            <template #cell(username)="data">
              <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.phoneno }}<br></span>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ data.item.fname }} {{ data.item.lname }}
            </template>
            <template #cell(invited_by)="data">
              <b-badge
                v-if="!data.item.invited_by"
                :variant="'light-danger'"
              >
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.refer_by.phoneno }}<br></span>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge>
              </div>
            </template>
            <template #cell(btn_detail)="">
              <div style="text-align:center;">
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="memberInfo">
      <b-col md="12">
        <b-card>
          <h2>ดาวน์ไลน์ติดตัว (ชั้นลูก)</h2>
          <b-table
            ref="downlineTable"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="affiliateInfo.l1childs"
            :fields="affFieldsL1"
          >
            <template #cell(username)="data">
              <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.phoneno }}<br></span>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ data.item.fname }} {{ data.item.lname }}
            </template>
            <template #cell(invited_by)="data">
              <b-badge
                v-if="!data.item.invited_by"
                :variant="'light-danger'"
              >
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.refer_by.phoneno }}<br></span>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="memberInfo">
      <b-col md="12">
        <b-card>
          <h2>ดาวน์ไลน์ลึก (ชั้นหลาน)</h2>
          <b-table
            ref="downlineTable"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="affiliateInfo.l2childs"
            :fields="affFieldsL2"
          >
            <template #cell(username)="data">
              <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.phoneno }}<br></span>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ data.item.fname }} {{ data.item.lname }}
            </template>
            <template #cell(invited_by)="data">
              <b-badge
                v-if="!data.item.invited_by"
                :variant="'light-danger'"
              >
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.refer_by.phoneno }}<br></span>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge>
              </div>
            </template>
            <template #cell(btn_detail)="">
              <div style="text-align:center;">
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="memberInfo">
      <b-col md="12">
        <b-card>
          <h2>ดาวน์ไลน์ลึก (ชั้นเหลน)</h2>
          <b-table
            ref="downlineTable"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="affiliateInfo.l3childs"
            :fields="affFieldsL3"
          >
            <template #cell(username)="data">
              <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.phoneno }}<br></span>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ data.item.fname }} {{ data.item.lname }}
            </template>
            <template #cell(invited_by)="data">
              <b-badge
                v-if="!data.item.invited_by"
                :variant="'light-danger'"
              >
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.refer_by.phoneno }}<br></span>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BFormFile, BTable, BBadge, BFormGroup, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BFormTimepicker, BCardText, BImg, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormFile,
    BCardText,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BImg,
    Cleave,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormTimepicker,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      slipImage: false,
      pageOptions: [100, 200, 500, 1000],
      bankaccounts: [],
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      affFieldsUL: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'created_at', label: 'สมัครเมื่อ', formatter: val => this.formatDate(val) },
        { key: 'updated_at', label: 'เข้าเล่นล่าสุด', formatter: val => this.formatDate(val) },
        { key: 'status', label: 'สถานะ' },
        { key: 'credit', label: 'ยอดเครดิตปัจจุบัน' },
        { key: 'btn_detail', label: 'จัดการ' },
      ],
      affFields: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'created_at', label: 'สมัครเมื่อ', formatter: val => this.formatDate(val) },
        { key: 'updated_at', label: 'เข้าเล่นล่าสุด', formatter: val => this.formatDate(val) },
        { key: 'status', label: 'สถานะ' },
        { key: 'credit', label: 'ยอดเครดิตปัจจุบัน' },
        { key: 'receivable_amount', label: 'ค่าแนะนำ (ยังไม่รับ)' },
        { key: 'received_amount', label: 'ค่าแนะนำ (รับแล้ว)' },
        { key: 'btn_detail', label: 'จัดการ' },
      ],
      affFieldsL1: [],
      affFieldsL2: [],
      affFieldsL3: [],
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      memberInfo: null,
      affiliateInfo: {},
      promotionsWallets: {},
      labels: {
        th: {
          labelHours: 'ชั่วโมง',
          labelMinutes: 'นาที',
          labelSeconds: 'วินาที',
          labelIncrement: 'เพิ่ม',
          labelDecrement: 'ลด',
          labelSelected: 'เลือก',
          labelNoTimeSelected: 'เลือกเวลา',
        },
      },
      promotionEvents: [],
      promotions: [],
      /* eslint-disable global-require */
    }
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter(f => f.sortable)
    //     .map(f => ({ text: f.label, value: f.key }))
    // },
    more_turn_amount() {
      return this.topupInfo.more_turn_amount || 0
    },
  },
  watch: {
    more_turn_amount(newVal) {
      this.topupInfo.withthdrawable_turn_amount = parseFloat((this.topupInfo.more_turn_amount || '').replace(/,/ig, '')) + parseFloat(this.memberInfo.total_turn_amount)
    },
  },
  mounted() {
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined)
      this.tableQuery.phoneno = this.$route.params.user
    this.fetchRecords()
    this.fetchBankAccounts()
    this.fetchPromotions()
  },
  methods: {
    calculateWithdrawTurnAmount() {
      console.log('calculateWithdrawTurnAmount')
      this.topupInfo.withthdrawable_turn_amount = parseFloat(this.topupInfo.more_turn_amount) + parseFloat(this.topupInfo.amount)
    },
    searchMember() {
      this.fetchRecords()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.memberInfo = null
      this.promotionsWallets = {}
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts')
      this.bankaccounts = _bankaccounts.bankaccounts.data.map(i => ({ ...i, title: `${i.bank} ${i.acc_no} ${i.acc_name}` })).filter(bank => ['IN', 'IN-OUT'].indexOf(bank.acc_type) >= 0)
      this.bankaccounts.push({
        title: 'อื่นๆ', acc_no: 'other', bank: 'other', acc_name: 'อื่นๆ',
      })
      // this.bankaccounts
    },
    async fetchRecords() {
      if (this.tableQuery.phoneno === null || this.tableQuery.phoneno === undefined) {
        this.items = []
        return this.items
      }

      let fieldName = 'phoneno'
      if (/[a-zA-Z]/.test(this.tableQuery.phoneno))
        fieldName = 'username'

      const { data: memberApiResult } = await this.$http.get(`/users/user-info?with_deposits=N&with_affiliate=Y&with_promotion_stat=Y&searchBy=${fieldName}&${fieldName}=${this.tableQuery.phoneno}&user=${this.tableQuery.phoneno}`)
      this.memberInfo = memberApiResult.user
      this.affiliateInfo = memberApiResult.affiliateInfo
      this.promotionsWallets = {}
      if (memberApiResult.success) {
        (memberApiResult.promotionsInfo.promotionWallets || []).forEach(item => {
          this.promotionsWallets[`${item.promotion_id}-${item.reference_id}`] = item
        })
      }
      // this.items = _wallettx.message.data
      // this.totalRows = _wallettx.message.total
      return this.items
    },
    async delay(x) {
      return new Promise(resolve => {
        setTimeout(() => resolve(true), x * 1000)
      })
    },
    async fetchPromotions() {
      try {
        const { data } = await this.$http.get('promotions')
        if (data.success) {
          this.promotionEvents = data.promotionEvents
          this.promotions = data.promotions.data

          this.affFields = [
            { key: 'username', label: 'ยูสเซอร์' },
            { key: 'fullname', label: 'ชื่อลูกค้า' },
            { key: 'created_at', label: 'สมัครเมื่อ', formatter: val => this.formatDate(val) },
            { key: 'updated_at', label: 'เข้าเล่นล่าสุด', formatter: val => this.formatDate(val) },
            { key: 'status', label: 'สถานะ' },
            { key: 'credit', label: 'ยอดเครดิตปัจจุบัน' },
          ]

          this.affFieldsL1 = [...this.affFields]
          this.affFieldsL2 = [...this.affFields]
          this.affFieldsL3 = [...this.affFields]
          const affPromotionsL1 = this.promotions.filter(p => ['ROLLING-L1-CHILD', 'CASHBACK-L1-CHILD'].indexOf(p.event_type) >= 0)
          affPromotionsL1.forEach(affPro => {
            this.affFieldsL1.push({
              key: `pro${affPro.id}`,
              label: `${affPro.title}`,
              formatter: (val, key, item) => `${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).unreceive_amount || 0} / ${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).received_amount || 0}`,
            })
          })

          const affPromotionsL2 = this.promotions.filter(p => ['ROLLING-L2-CHILD', 'CASHBACK-L2-CHILD'].indexOf(p.event_type) >= 0)
          affPromotionsL2.forEach(affPro => {
            this.affFieldsL2.push({
              key: `pro${affPro.id}`,
              label: `${affPro.title}`,
              formatter: (val, key, item) => `${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).unreceive_amount || 0} / ${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).received_amount || 0}`,
            })
          })

          const affPromotionsL3 = this.promotions.filter(p => ['ROLLING-L3-CHILD', 'CASHBACK-L3L32-CHILD'].indexOf(p.event_type) >= 0)
          affPromotionsL3.forEach(affPro => {
            this.affFieldsL3.push({
              key: `pro${affPro.id}`,
              label: `${affPro.title}`,
              formatter: (val, key, item) => `${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).unreceive_amount || 0} / ${(this.promotionsWallets[`${key.replace('pro', '')}-${item.id}`] || {}).received_amount || 0}`,
            })
          })

          // this.affFields.push({ key: 'btn_detail', label: 'จัดการ' })
        }
        console.log('promotion', data)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
